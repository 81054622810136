<template>
    <div class="container">
        <div class="box">
            <section class="form pattern-customer">
                <router-link :to="routerPath.LOGIN" class="back" title="ลงชื่อเข้าใช้"><b-icon icon="chevron-left"></b-icon> ลงชื่อเข้าใช้</router-link>

                <div class="wrapper">
                    <h1>ลืมรหัสผ่าน</h1>

                    <div class="input-field" v-if="step < 3">
                        <label>หมายเลขโทรศัพท์</label>
                        <div class="input-button">
                            <input type="tel"
                                @keypress="$AppUtility.inputOnlyNumber($event)"
                                maxlength="12"
                                v-model="form.tel"
                                placeholder="ระบุหมายเลขโทรศัพท์"
                                class="square lg" 
                                :class="{ 'invalid': error.tel }"
                                :readonly="countDownOTP > 0 || step > 2" />
                            <button class="btn btn-main square" @click="requestOTP()" v-if="step === 1">ขอ OTP</button>
                            <button class="btn btn-main square" @click="requestOTP()" v-if="step === 2 && countDownOTP === 0">ส่งอีกครั้ง</button>
                        </div>
                        <div class="otp-countdown" v-if="countDownOTP > 0">ลองใหม่อีกครั้งใน <span>{{ helper.displayCountDownOTP(countDownOTP) }}</span></div>
                    </div>

                    <div class="input-field mt-2" v-if="step === 2">
                        <label>SMS ยืนยันตัวตน</label>
                        <input type="tel"
                            @keypress="$AppUtility.inputOnlyNumber($event)"
                            maxlength="6"
                            v-model="form.otp"
                            placeholder="ตัวเลข 6 หลัก"
                            class="square lg" 
                            :class="{ 'invalid': error.otp }" />
                    </div>

                    <template v-if="step === 3">
                        <gadget-policy-input class="mt-3" :showPassNote="true"></gadget-policy-input>

                        <div class="input-field mt-2">
                            <label>ตั้งรหัสผ่าน</label>
                            <div class="input-password">
                                <input :type="isShowPassword ? 'text' : 'password'"
                                    maxlength="50"
                                    v-model="form.password"
                                    placeholder="รหัสผ่าน"
                                    class="square lg" 
                                    :class="{ 'invalid': error.password }" />
                                <button class="show-password" @click="showPassword()"><b-icon :icon="isShowPassword ? 'eye-slash-fill' : 'eye-fill'"></b-icon></button>
                            </div>
                        </div>
                        <div class="input-field mt-2">
                            <label>ยืนยันรหัสผ่าน</label>
                            <div class="input-password">
                                <input :type="isShowConfirmPassword ? 'text' : 'password'"
                                    maxlength="50"
                                    v-model="form.confirmPassword"
                                    placeholder="รหัสผ่าน"
                                    class="square lg" 
                                    :class="{ 'invalid': error.confirmPassword }" />
                                <button class="show-password" @click="showConfirmPassword()"><b-icon :icon="isShowPassword ? 'eye-slash-fill' : 'eye-fill'"></b-icon></button>
                            </div>
                        </div>
                    </template>

                    <div class="mt-4 text-center" v-if="step === 2">
                        <button class="btn btn-main btn-submit" @click="confirmOTP()">ยืนยัน OTP</button>
                    </div>
                    
                    <div class="mt-4 text-center" v-if="step === 3">
                        <button class="btn btn-main btn-submit" @click="resetGeneralUserPassword()">ตั้งรหัสผ่านใหม่</button>
                    </div>
                </div>
            </section>

            <section class="link pattern-customer">
                <div class="wrapper">
                    <h2>เป็นลูกค้าใหม่?</h2>
                    <p>ซื้อยางกับ Yellowtire ข้อมูลถูกต้อง ราคาถูกใจ มั่นใจได้แน่นอน</p>

                    <div class="action">
                        <router-link class="btn btn-warning" :to="routerPath.REGISTER">สมัครสมาชิกใหม่</router-link>
                    </div>
                </div>
            </section>
        </div>
	</div>
</template>

<script>
import RouterPath from '@/router/path';
import Helper from '@/services/helper';
import UserService from '@/services/userService';
import GadgetPolicyInput from '@/components/gadget/PolicyInput';

export default {
    components: { GadgetPolicyInput },
    data() {
        return {
            helper: Helper,
            routerPath: RouterPath,
			isShowPassword: false,
            isShowConfirmPassword: false,
            countDownOTP: 0,
            form: this.initForm(),
            error: this.initError(),
            step: 1,
            otpToken: ''
        }
    },
    methods: {
        initForm() {
            return {
                tel: '',
                otp: '',
                hashTel: '',
                password: '',
                confirmPassword: ''
            };
        },
        initError() {
            return {
                tel: false,
                otp: false,
                password: false,
                confirmPassword: false
            };
        },
        countDownTimer() {
            if (this.countDownOTP > 0) {
                setTimeout(() => {
                    this.countDownOTP -= 1
                    this.countDownTimer()
                }, 1000);
            }
        },
        async requestOTP() {
            if (this.validateTel()) {
                let loader = this.$modalLoader.render();

                const recaptcha = await this.getRecaptchaToken();
                const result = await UserService.requestOTP(this.form.tel, recaptcha);

                loader.hide();

                if (result.data?.success) {
                    this.countDownOTP = 59;
                    this.countDownTimer();
                    this.otpToken = result.data.token;
                    this.step = 2;
                } else {
                    this.$swal(Helper.warningAlert('ขอ OTP ไม่สำเร็จ', result.data?.message));
                }
            }
        },
        async getRecaptchaToken() {
            await this.$recaptchaLoaded()

            const recaptcha = await this.$recaptcha('customerForgetPassword');

            return recaptcha;
        },
        validateTel() {
            this.error = this.initError();
            let isValid = true;
            let isBlank = false;

            // Check Blank
            if (!this.form.tel?.trim()) {
                this.error.tel = true;
                isValid = false;
                isBlank = true;
            }

            // Popup
            if (isBlank) {
                this.$swal(Helper.warningAlert('ขอ OTP ไม่สำเร็จ', 'โปรดกรอกเบอร์โทรศัพท์ของท่าน'));
            }

            return isValid;
        },
        async confirmOTP() {
            if (this.validateOTP()) {
                let loader = this.$modalLoader.render();
                const result = await UserService.submitOTP('forget', this.form.tel, this.form.otp, this.otpToken);
                loader.hide();

                if (result.data?.success) {
                    this.form.hashTel = result.data.key;
                    this.step = 3;

                    // Hide countdown
                    this.countDownOTP = 0;
                } else {
                    this.$swal(Helper.warningAlert('ยืนยัน OTP ไม่สำเร็จ', result.data?.message));
                }
            }
        },
        validateOTP() {
            this.error = this.initError();
            let isValid = true;
            let isBlank = false;

            // Check Blank
            if (!this.form.otp?.trim()) {
                this.error.otp = true;
                isValid = false;
                isBlank = true;
            }

            // Popup
            if (isBlank) {
                this.$swal(Helper.warningAlert('ไม่สามารถดำเนินการต่อได้', 'โปรดกรอกหมายเลข OTP'));
            }

            return isValid;
        },
        async resetGeneralUserPassword() {
            if (this.validatePassword()) {
                let loader = this.$modalLoader.render();
                const result = await UserService.resetGeneralUserPassword(this.form.hashTel, this.form.password);
                loader.hide();

                if (result.data?.success) {
                    this.$swal(
                        Helper.completeAlert('ตั้งรหัสผ่านใหม่สำเร็จแล้ว', 'โปรดทำการเข้าสู่ระบบอีกครั้งด้วยรหัสผ่านใหม่')
                    ).then(() => {
                        this.$router.push(RouterPath.LOGIN);
                    });
                } else {
                    this.$swal(Helper.warningAlert('ตั้งรหัสผ่านใหม่ไม่สำเร็จ', result.data?.message));
                }
            }
        },
        validatePassword() {
            this.error = this.initError();
            let isValid = true;
            let isBlank = false;
            let isNotMatchPassword = false;
            let isInvalidPassword = false;

            // Check Blank
            if (!this.form.password?.trim()) {
                this.error.password = true;
                isValid = false;
                isBlank = true;
            }

            if (!this.form.confirmPassword?.trim()) {
                this.error.confirmPassword = true;
                isValid = false;
                isBlank = true;
            }

            // Compare Password
            if (this.form.password !== this.form.confirmPassword) {
                this.error.confirmPassword = true;
                isValid = false;
                isNotMatchPassword = true;
            }

            // Check Password Format
            const passwordPolicy = Helper.validatePasswordFormat(this.form.password);

            if (!passwordPolicy.isValid) {
                this.error.password = true;
                isValid = false;
                isInvalidPassword = true;
            }

            // Popup
            const title = 'ตั้งรหัสผ่านไม่สำเร็จ';

            if (isBlank) {
                this.$swal(Helper.warningAlert(title, 'โปรดกรอกรหัสผ่านและยืนยืนให้ถูกต้อง'));
            } else if (isNotMatchPassword) {
                this.$swal(Helper.warningAlert(title, 'ยืนยันรหัสผ่านไม่ถูกต้อง'));
            } else if (isInvalidPassword) {
                this.$swal(Helper.warningAlert('รหัสผ่านไม่ตรงตามเงื่อนไขด้านล่าง', '', Helper.getWarningPolicyMessage(passwordPolicy.message)));
            }

            return isValid;
        },
        showPassword() {
            this.isShowPassword = !this.isShowPassword;
        },
        showConfirmPassword() {
            this.isShowConfirmPassword = !this.isShowConfirmPassword;
        }
    },
    metaInfo() {
		const title = 'ลืมรหัสผ่าน | YELLOWTiRE';
		const desc = 'ลืมรหัสผ่านสมาชิก YELLOWTiRE';
		const image = location.origin + Helper.metaDefault().logo;
		
        return {
            title: title,
            meta: [
                { vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
                { vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword },
                { vmid: 'desc', name: 'description', content: desc },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: title },
                { vmid: 'og-desc', property: 'og:description', content: desc },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: image },
                { vmid: 'twitter-description', property: 'twitter:description', content: desc },
                { vmid: 'twitter-title', property: 'twitter:title', content: title },
                { vmid: 'twitter-image', property: 'twitter:image', content: image }
            ]
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/components/user/style/Authentication.scss';
</style>